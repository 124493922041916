import Head from "next/head";

import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";
import "../components/select/select.css";
import "swiper/css";
import "swiper/css/navigation";
import "./../styles/swiper-styles.css";
import { config } from "@fortawesome/fontawesome-svg-core";
import "../node_modules/@fortawesome/fontawesome-svg-core/styles.css";
import "./hide-banner.css";
import "./theming-colors.css";
import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";
import { Capacitor } from "@capacitor/core";
config.autoAddCss = false;

function MyApp({ Component, pageProps }) {
  return (
    <>
      <Head>
        {Capacitor.isNativePlatform() ? (
          <meta
            name="viewport"
            content="minimum-scale=1.0, maximum-scale=1.0, user-scalable=no, initial-scale=1.0, viewport-fit=cover, width=device-width"
          />
        ) : (
          <meta
            name="viewport"
            content="minimum-scale=1.0, maximum-scale=5.0, initial-scale=1.0, user-scalable=yes, viewport-fit=cover, width=device-width"
          />
        )}
      </Head>
      <Component {...pageProps} />
    </>
  );
}

export default MyApp;
